<template>
  <div class="components-form-advanced-search">
    <!-- search start -->
    <a-form class="ant-advanced-search-form" :form="searchForm">
      <a-row :gutter="24">
        <a-col :span="6" style="display: block">
          <a-form-item label="保险公司名称">
            <a-input placeholder="保险公司名称" v-model="searchForm.insuranceName" />
          </a-form-item>
        </a-col>
        <a-col :span="6" style="display: block">
          <a-form-item label="保单号">
            <a-input placeholder="保单号" v-model="searchForm.PolicyNo" />
          </a-form-item>
        </a-col>
        <a-col :span="6" style="display: block">
          <a-form-item label="车牌号">
            <a-input placeholder="车牌号" v-model="searchForm.PlateNo" />
          </a-form-item>
        </a-col>
        <a-col :span="6" style="display: block">
          <a-form-item label="投保人">
            <a-input placeholder="投保人" v-model="searchForm.HolderName" />
          </a-form-item>
        </a-col>
        <a-col :span="6" :style="{ display: expand ? 'block' : 'none' }">
          <a-form-item label="归属机构名称">
            <a-input placeholder="归属机构名称" v-model="searchForm.Department" />
          </a-form-item>
        </a-col>
        <a-col :span="6" :style="{ display: expand ? 'block' : 'none' }">
          <a-form-item label="归属修理厂">
            <a-input placeholder="归属修理厂" v-model="searchForm.Salesman" />
          </a-form-item>
        </a-col>
        <a-col :span="6" :style="{ display: expand ? 'block' : 'none' }">
          <a-form-item label="数据来源">
            <a-input placeholder="数据来源" v-model="searchForm.DataFrom" />
          </a-form-item>
        </a-col>
        <a-col :span="6" :style="{ display: expand ? 'block' : 'none' }">
          <a-form-item label="建立时间">
            <a-range-picker v-model="searchForm.CreatedAt" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24" :style="{ textAlign: 'right' }">
          <a-button type="primary" @click="searchClick"> 查询 </a-button>
          <a-button :style="{ marginLeft: '8px' }" @click="cleanSearchClick">
            清除
          </a-button>
          <a
            :style="{ marginLeft: '8px', fontSize: '12px' }"
            @click="expand = !expand"
          >
            {{ expand ? "收起" : "展开" }}
            <a-icon :type="expand ? 'up' : 'down'" />
          </a>
        </a-col>
      </a-row>
    </a-form>

    <!-- search end -->
    <!-- table start -->
    <div class="app-card">
      <!-- <a-alert message="单次上传核对数据不能大于2000条" type="warning" show-icon />
      <a-row style="padding:16px 0" type="flex" justify="end">
        <a-popover title="导入模板" v-if="($root.$data.privateState.action.includes('/CarOrders/ImportCheckList') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role))">
            <template slot="content">
              <a :href="`${$appBaseUrl}/excelTmp/保单数据核对.xlsx`" target="_blank" download>下载模板</a>
            </template>
            <a-button icon="cloud-upload" type="primary" @click="uploadFileClick">导入核对数据</a-button>
          </a-popover>
      </a-row> -->
      <!-- <a-row style="padding-bottom:16px" type="flex" justify="end">
        <a-button icon="cloud-upload" type="danger" @click="deleteBatch">删除导入批次</a-button>
      </a-row> -->
      <a-row :gutter="[16,16]" type="flex" justify="start">
        <a-col style="display: flex;">
          <a-popover title="批量导入" v-if="$root.$data.privateState.action.includes('/CarOrders/UploadCarPolicy') ||
                ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role)
              ">
              <template slot="content">
                <a-row :gutter="[8, 24]">
                  <a-col><a :href="`${$appBaseUrl}/excelTmp/美联承保清单导入模板.xlsx`"  target="_blank" download>美联承保清单导入模板</a></a-col>
                  <a-col><a :href="`${$appBaseUrl}/excelTmp/保行承保清单导入模板.xlsx`"  target="_blank" download>保行承保清单导入模板</a></a-col>
                  <a-col><a :href="`${$appBaseUrl}/excelTmp/泰好代理承保清单导入模板.xlsx`"  target="_blank" download>泰好代理承保清单导入模板</a></a-col>
                </a-row>
              </template>
              <a-upload
                name="file"
                :file-list="uploadList"
                :action="fileUrl"
                :headers="fileUploadHeaders"
                :multiple="false"
                @change="handleChangeFile"
              >
                <a-button icon="cloud-upload" type="primary">批量导入</a-button>
              </a-upload>
          </a-popover>
          <a-divider type="vertical" style="height:100%" />
          <a-button v-if="$root.$data.privateState.action.includes('/CarOrders/UploadCarPolicy') ||
                ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role)
              " style="background-color: #13c2c2;color: #fff;border-color: #13c2c2;" icon="sync" @click="rematchUnmatchedOrders">刷新未匹配定损政策保单</a-button>
          <a-divider type="vertical" style="height:100%" />
            <a-button  icon="cloud-upload" type="danger" @click="showReturnBatchClick" v-if="($root.$data.privateState.action.includes('/CarOrders/ReturnMatch') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role))">撤回导入批次积分匹配</a-button>

        </a-col>
      </a-row>
      <!-- table 控制 start -->
      <a-row style="padding-bottom: 16px" type="flex" justify="end">
        <a-col>
          <a-button-group>
            <a-tooltip placement="top">
              <template slot="title">
                <span>密度</span>
              </template>
              <a-dropdown :trigger="['click']">
                <a-button
                  style="color: rgba(0, 0, 0, 0.65)"
                  type="link"
                  icon="column-height"
                />
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a
                      href="javascript:;"
                      :class="
                        tableTdStyle === 'default'
                          ? 'ant-table-td-style-selected'
                          : ''
                      "
                      @click="tableTdStyle = 'default'"
                      >默认</a
                    >
                  </a-menu-item>
                  <a-menu-item>
                    <a
                      href="javascript:;"
                      :class="
                        tableTdStyle === 'middle'
                          ? 'ant-table-td-style-selected'
                          : ''
                      "
                      @click="tableTdStyle = 'middle'"
                      >中等</a
                    >
                  </a-menu-item>
                  <a-menu-item>
                    <a
                      href="javascript:;"
                      :class="
                        tableTdStyle === 'small'
                          ? 'ant-table-td-style-selected'
                          : ''
                      "
                      @click="tableTdStyle = 'small'"
                      >紧凑</a
                    >
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>全屏</span>
              </template>
              <a-button
                style="color: rgba(0, 0, 0, 0.65)"
                type="link"
                :icon="isFullscreen ? 'fullscreen-exit' : 'fullscreen'"
                @click="
                  () => {
                    screenfull.toggle();
                    isFullscreen = !isFullscreen;
                  }
                "
              />
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>刷新</span>
              </template>
              <a-button
                style="color: rgba(0, 0, 0, 0.65)"
                type="link"
                icon="reload"
                @click="searchClick"
              />
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>列设置</span>
              </template>
              <a-popover trigger="click" placement="bottom">
                <template slot="content">
                  <a-checkbox-group
                    :value="showColumns"
                    @change="changeColumns"
                  >
                    <a-row>
                      <a-col v-for="it of baseColumns" :key="it">
                        <a-checkbox :value="it">
                          {{ it }}
                        </a-checkbox>
                      </a-col>
                    </a-row>
                  </a-checkbox-group>
                </template>
                <a-button
                  style="color: rgba(0, 0, 0, 0.65)"
                  type="link"
                  icon="setting"
                />
              </a-popover>
            </a-tooltip>
          </a-button-group>
        </a-col>
      </a-row>
      <!-- table 控制 end -->
      <a-table
        :columns="columns"
        :rowKey="(record) => record.policyNo"
        :dataSource="tableData"
        :pagination="pagination"
        :loading="loading"
        :scroll="{ x: 1300 }"
        @change="handleTableChange"
        bordered
        :size="tableTdStyle"
      >
        <template #payDate="payDate">
          {{ payDate ? moment(payDate).format("YYYY-MM-DD HH:mm:ss") : '无' }}
        </template>
        <template #salesmanId="salesmanId">
          <a-tag v-if="salesmanId" color="green">已匹配</a-tag>
          <a-tag v-else color="orange">未匹配</a-tag>
        </template>
        <template #lossRateModelUuid="lossRateModelUuid">
          <a-tag v-if="lossRateModelUuid" color="green">已匹配</a-tag>
          <a-tag v-else color="orange">未匹配</a-tag>
        </template>
        <!-- <template #action="data">
          <a-button
            v-if="
              $root.$data.privateState.role === 'SuperAdmin' ||
              $root.$data.privateState.action.includes('/CarOrders/CarOrderDetail')
            "
            type="link"
            @click="showDetail(data)"
            >详情</a-button
          >
        </template> -->
      </a-table>
    </div>
    <!-- table end -->
    <a-modal
      v-model="showReturnBatchModal"
      @ok="returnBatchOk"
      title="撤回导入批次积分匹配"
    >
      <a-input addon-before="批次号" v-model="returnBatchNo" />
    </a-modal>
    <a-modal
      :visible="sending"
      :footer="null"
      :maskClosable="false"
      :keyboard="false"
      :closable="false"
    >
      <div style="text-align: center">
        <div>{{ sendingHint }}</div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import licia from "licia";
import moment from "moment";
import screenfull from "screenfull";
// import DownloadJS from 'downloadjs'
import { CarOrders } from "@/api";
import { aliveVM, mixinTableList } from "@/utils/mixin";

const INIT_SEARCH_FORM = '{"insuranceName":"","PolicyNo":"","PlateNo":"","HolderName":"","PayTime":[],"Department":"","Salesman":"","Status":"","DataFrom":"","CreatedAt":[],"Type":"", "HasRate":""}';
const columns = [
  {
    title: "保单号",
    width: 150,
    dataIndex: "policyNo",
  },
  {
    title: "保险公司名称",
    width: 150,
    dataIndex: "insuranceName",
  },
  {
    title: "保险公司工号",
    width: 150,
    dataIndex: "insuranceAccountUsername",
  },
  {
    title: "保单类型",
    width: 150,
    dataIndex: "insType",
  },
  {
    title: "车牌号",
    width: 150,
    dataIndex: "plateNo",
  },
  {
    title: "支付时间",
    width: 150,
    dataIndex: "payDate",
    scopedSlots: { customRender: "payDate" },
  },
  {
    title: "保费",
    width: 150,
    dataIndex: "amount",
  },
  {
    title: "修理厂(数据导入或推送)",
    width: 150,
    dataIndex: "salesman",
  },
  {
    title: "已匹配修理厂",
    width: 85,
    dataIndex: "salesmanId",
    scopedSlots: { customRender: "salesmanId" },
  },
  {
    title: "已匹配定损政策",
    width: 85,
    dataIndex: "lossRateModelUuid",
    scopedSlots: { customRender: "lossRateModelUuid" },
  },
  {
    title: "导入批次号",
    width: 150,
    dataIndex: "batchNo",
  },
  // {
  //   title: "操作",
  //   key: "operation",
  //   width: 130,
  //   fixed: "right",
  //   scopedSlots: { customRender: "action" },
  // },
];
export default {
  name: "OrderCar",
  mixins: [aliveVM, mixinTableList],
  created() {
    this.fileUrl = `${this.$appBaseUrl}${CarOrders}/UploadCarPolicy`
    this.fileUploadHeaders = {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
    const baseColumns = [];
    for (const it of columns) {
      baseColumns.push(it.title);
    }
    this.$set(this, "baseColumns", baseColumns);
    this.fetch();
  },
  computed: {
    showColumns() {
      let c = [];
      for (const it of this.columns) {
        c.push(it.title);
      }
      return c;
    },
  },
  data() {
    return {
      sending: false,
      sendingHint: "",
      moment,
      screenfull,
      isFullscreen: false,
      expand: false,
      tableListApi: `${CarOrders}/TableList`,
      searchParams: null,
      searchForm: JSON.parse(INIT_SEARCH_FORM),
      baseColumns: [],
      columns,
      // 其他
      showDetailModal: false,
      showDetailData: null,
      // 导入
      uploadList: [],
      fileUrl: '',
      fileUploadHeaders: {},
      // 撤回导入批次积分匹配
      showReturnBatchModal: false,
      returnBatchNo: ''
    };
  },
  methods: {
    changeColumns(v) {
      const c = [];
      for (const it of columns) {
        if (v.includes(it.title)) {
          c.push(it);
        }
      }
      this.$set(this, "columns", c);
    },
    async reload() {
      this.$set(this, "searchParams", null);
      this.searchForm.resetFields();
      await this.fetch();
    },
    async cleanSearchClick() {
      this.$set(this, "searchParams", null);
      this.$set(this, "searchForm", JSON.parse(INIT_SEARCH_FORM));
      await this.fetch();
    },
    async searchClick() {
      this.pagination.current = 1;
      const searchForm = JSON.parse(JSON.stringify(this.searchForm));
      const searchParams = {};
      for (const k of Object.keys(searchForm)) {
        const it = searchForm[k];
        if (k === 'PayTime' || k === 'CreatedAt') {
          if (!it || it.length < 1) continue
          it[0] = moment(it[0]).format('YYYY-MM-DD') + " 00:00:00"
          it[1] = moment(it[1]).format('YYYY-MM-DD') + " 23:59:59"
          searchParams[k] = it
        }
        if (licia.isNum(it)) {
          searchParams[k] = it;
          continue;
        }
        if (!licia.isEmpty(it)) {
          searchParams[k] = it;
        }
      }
      this.$set(this, "searchParams", searchParams);
      this.fetch();
    },
    // other
    // 其他
    async showDetail(data) {
      const res = await this.$axios
        .get(`${CarOrders}/CarOrderDetail?uuid=${data.uuid}`)
        .catch((e) => {
          return {
            status: false,
            message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
          };
        });
      if (!res.status) {
        this.$message.error(res.message);
        return;
      }
      this.$set(this, "showDetailData", res.data);
      this.showDetailModal = true;
    },
    handleChangeFile (info) {
      this.uploadList = [info.fileList[info.fileList.length - 1]]
      if (info.file.status === 'error') {
        this.$message.error('上传文件失败，请尝试重新登录');
        return
      } else if (info.file.status === 'done') {
        this.errorList = []
        if (!info.file.response.status) {
          this.$message.error(info.file.response.message);
          if (info.file.response.data) {
            this.errorList = info.file.response.data
            this.showErrorModal = true
          }
          return;
        }
        const _this = this
          this.$confirm({
            title: '导入数据成功',
            content: `导入数据将经过数据推送处理，之后请通过在保险订单模块查询批次号——${info.file.response.data}——核对正确导入，错误导入请进入推送错误日志查询批次号确认,数据处理需要经过一段时间，如果没有显示导入数据，请稍后刷新查看`,
            onOk() {
              _this.fetch();
            },
            onCancel() {
              _this.fetch();
            },
          });
        
      }
    },
    async rematchUnmatchedOrders() {
      this.sending = true
      this.sendingHint = '正在获取数据'
      const res = await this.$axios
        .get(`${CarOrders}/RematchUnmatchedOrders`)
        .catch((e) => {
          return {
            status: false,
            message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
          };
        });
      this.sending = false
      if (!res.status) {
        this.$message.error(res.message);
        return;
      }
      this.$message.success("刷新完成")
      this.fetch();
    },
    showReturnBatchClick() {
      this.returnBatchNo = ''
      this.showReturnBatchModal = true
    },
    async returnBatchOk() {
      if (!this.returnBatchNo) {
        this.$message.error("请填写批次号")
        return
      }
      this.sending = true
      this.sendingHint = '正在处理数据'
      const apiRes = await this.$axios.post(`${CarOrders}/ReturnMatch?batchNo=${this.returnBatchNo}`)
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      this.sending = false
      if (!apiRes.status) {
        this.$message.error(apiRes.message);
        return;
      }
      this.$message.success("撤回导入批次积分匹配成功！")
      this.showReturnBatchModal = false
      this.fetch();
    }
  },
};
</script>

<style>
</style>